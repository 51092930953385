<template>
  <section class="section__content">
    <h2 class="section__title">3. Completa los datos de tu peludo</h2>
    <div class="info__content">
      <div class="info__input">
        <h3>Frente</h3>
        <div class="input__content">
          <label for="name">Nombre:</label>
          <input id="name" value="text" v-model="name" @input="emitName" />
        </div>
        <div class="input__content">
          <label for="nickname">Apodo:</label>
          <input
            id="nickname"
            type="text"
            v-model="nickname"
            @input="emitNickName"
          />
        </div>
      </div>
      <div class="info__input">
        <h3>Reverso</h3>
        <div class="input__content">
          <label for="phone">Celular #1:</label>
          <input
            id="phone"
            type="tel"
            value="3104350594"
            v-model="phone"
            @input="emitPhone"
          />
        </div>
        <div class="input__content">
          <label for="altPhone">Info adicional:</label>
          <input
            id="altPhone"
            type="text"
            v-model="altPhone"
            @input="emitAltPhone"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      name: "Paco",
      nickname: "El cansón",
      phone: "0123456789",
      altPhone: "@thepacostore.co",
    };
  },
  mounted() {
    this.emitName();
    this.emitNickName();
    this.emitPhone();
    this.emitAltPhone();
  },
  methods: {
    emitName() {
      this.$emit("name", this.name);
    },
    emitNickName() {
      this.$emit("nickname", this.nickname);
    },
    emitPhone() {
      this.$emit("phone", this.phone);
    },
    emitAltPhone() {
      this.$emit("altPhone", this.altPhone);
    },
  },
};
</script>
<style lang="scss" scoped>
.info__content {
  display: flex;
  justify-content: center;
  gap: 20px;

  @media all and (max-width: 450px) {
    flex-direction: column;
  }
}

.info__input {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 16px;
    letter-spacing: 4px;
    text-transform: uppercase;
    color: #0a5753;
  }
}

input {
  border: solid 1px #b3b3b2;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  color: #2b2f2f;
  touch-action: manipulation;
  margin-top: 5px;
}

label {
  font-weight: bold;
  font-size: 16px;
  color: #0a5753;
}

.input__content {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 10px;

  @media all and (max-width: 450px) {
    width: 100%;

    & > input {
      width: 100%;
    }
  }
}
</style>
