<template>
  <section class="section section__content section__content--color">
    <h2 class="section__title">4. ¿Cuál tipo de letra prefieres?</h2>
    <div class="fonts">
      <div
        class="font font--avenir"
        :class="{
          'font--active': font === 'avenir',
        }"
        @click="emitFont('avenir')"
      >
        Aa
      </div>
      <div
        class="font font--bebas"
        :class="{
          'font--active': font === 'bebas',
        }"
        @click="emitFont('bebas')"
      >
        Aa
      </div>
      <div
        class="font font--salsa"
        :class="{
          'font--active': font === 'salsa',
        }"
        @click="emitFont('salsa')"
      >
        Aa
      </div>
    </div>

    <button
      @click="$emit('showPopup')"
      class="button button--pulse button--yellow button--info"
    >
      ¡Quiero esta placa! 🤗
    </button>
  </section>
</template>
<script>
export default {
  data() {
    return {
      font: "avenir",
    };
  },
  methods: {
    emitFont(font) {
      this.font = font;
      this.$emit("setFont", this.font);
    },
  },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Salsa&display=swap");

.fonts {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.font {
  width: 100px;
  height: 50px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2em;
  border-radius: 4px;
  cursor: pointer;
  color: #0a5753;

  &--active {
    background-color: #f3b64e;
  }

  &--bebas {
    font-family: "Bebas Neue", sans-serif;
  }
  &--salsa {
    font-family: "Salsa", cursive;
  }
}
</style>
