export const COLORS = {
  "#0382c7": "blue",
  "#ee7159": "coral",
  "#95d777": "green",
  "#ffaa2f": "orange",
  "#fe6aa5": "pink",
  "#c1201f": "red",
  "#d6469f": "violet",
  "#f4db27": "yellow",
};

export const orderMapper = (order) => {
  return {
    shape: order.shape,
    color: COLORS[order.color] ?? order.color,
    font: order.font,
    info: {
      name: order.name,
      nickname: order.nickname,
      phone: order.phone,
      altPhone: order.altPhone,
    },
    date: order.date,
    status: "PENDING",
  };
};
