var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section section__content section__content--color"},[_c('h2',{staticClass:"section__title"},[_vm._v("4. ¿Cuál tipo de letra prefieres?")]),_c('div',{staticClass:"fonts"},[_c('div',{staticClass:"font font--avenir",class:{
        'font--active': _vm.font === 'avenir',
      },on:{"click":function($event){return _vm.emitFont('avenir')}}},[_vm._v(" Aa ")]),_c('div',{staticClass:"font font--bebas",class:{
        'font--active': _vm.font === 'bebas',
      },on:{"click":function($event){return _vm.emitFont('bebas')}}},[_vm._v(" Aa ")]),_c('div',{staticClass:"font font--salsa",class:{
        'font--active': _vm.font === 'salsa',
      },on:{"click":function($event){return _vm.emitFont('salsa')}}},[_vm._v(" Aa ")])]),_c('button',{staticClass:"button button--pulse button--yellow button--info",on:{"click":function($event){return _vm.$emit('showPopup')}}},[_vm._v(" ¡Quiero esta placa! 🤗 ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }