import { collection, getDocs } from "firebase/firestore";

const getShapes = async (db) => {
  const collectionRef = collection(db, "shapes");
  try {
    const { docs } = await getDocs(collectionRef);
    const [shapes = {}] = docs;
    return shapes.data();
  } catch (e) {
    console.log(e);
    return {};
  }
};

export { getShapes };
