<template>
  <section class="section__content section__content--color">
    <h2 class="section__title">2. Elige tu color favorito</h2>
    <div class="colors" v-if="!loading">
      <div
        class="color"
        :key="key"
        v-for="(color, key) in availableColors"
        :class="{ 'color--active': isActive(color.color) }"
        :style="{ backgroundColor: color.color }"
        @click="setColor(color)"
      ></div>
    </div>
    <Loader v-else />
  </section>
</template>
<script>
import Loader from "../Loader.vue";
export default {
  props: {
    colors: {
      type: Object,
      default: () => ({}),
    },
    shape: {
      type: String,
      default: "",
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      activeColor: [],
      loading: true,
    };
  },
  watch: {
    shape() {
      if (this.mapColors.includes(this.activeColor)) {
        this.$emit("color", this.activeColor);
        return;
      }

      this.activeColor = this.initialValue;
      this.$emit("color", this.activeColor);
      this.loading = false;
    },
  },
  computed: {
    availableColors() {
      const { colors = undefined } = this.colors;

      if (!colors) {
        return [];
      }

      const filteredColors = Object.values(colors).filter(
        (color) => color.count > 0
      );
      return filteredColors;
    },
    mapColors() {
      return this.availableColors.map(({ color }) => color);
    },
    initialValue() {
      const [firstColor = ""] = this.availableColors;
      return firstColor.color;
    },
  },
  methods: {
    setColor({ color }) {
      this.activeColor = color;
      this.$emit("color", color);
    },
    isActive(color) {
      return this.activeColor.includes(color);
    },
  },
};
</script>
<style lang="scss" scope>
.colors {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.color {
  width: 45px;
  height: 45px;
  background: #bebebe;
  cursor: pointer;
  border-radius: 3px;
  position: relative;

  &--active {
    animation: pulse 1.5s infinite;

    &::before {
      width: 100%;
      height: 3px;
      content: "";
      position: absolute;
      left: 0;
      bottom: -6px;
      background: #f3b74f;
    }
  }
}
</style>
