<template>
  <div class="popup">
    <div class="popup__close" @click="$emit('closePopup')"></div>
    <div class="popup__content">
      <header class="header header__popup">
        <h3>Estás a tan solo un paso... 🙌🏻</h3>
      </header>
      <div class="popup__padding">
        <ul>
          <li>
            1. 🕵🏻‍♂️ El código del diseño de tu placa es:
            <span class="code">{{ code }}</span>
          </li>
          <li>
            2. 🤳🏼 ¡Contínua con el proceso de tu compra,
            <a class="link" :href="whatsappUrl" target="_blank">
              ¡haciendo clic aquí!</a
            >
          </li>
          <li>3. 🤩 ¡Prepárate para hacer realidad la placa de tus sueños!</li>
        </ul>

        <a
          :href="whatsappUrl"
          target="_blank"
          class="button button--yellow button--center"
        >
          ¡Completar compra! 😊
        </a>
        <p class="disclaimer">
          Esta herramienta nos permite crear una
          <u>vista previa de tu placa</u>, pero te aseguramos que la experiencia
          será aún mejor. ¡Estamos ansiosos por ayudarte a obtener la placa que
          deseas para tu peludo! 😘
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    code: {
      type: String,
      default: "",
    },
  },
  computed: {
    whatsappUrl() {
      const phoneNumber = "+573147555587";
      const message = `¡Hola! Quiero continuar con el proceso de compra de mi placa. Mi código es: ${this.code}`;

      const whatsappURL =
        "https://wa.me/" + phoneNumber + "?text=" + encodeURIComponent(message);

      return whatsappURL;
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  background: rgba(0, 0, 0, 0.9);
  height: 100%;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  &__padding {
    padding: 0 35px;
  }

  &__content {
    background: #fff;
    width: 500px;
    border-radius: 4px;
    overflow: hidden;

    text-align: left;

    @media all and (max-width: 510px) {
      width: 90%;
      padding: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 30px 0;

      li {
        margin-bottom: 10px;
      }
    }

    .header {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        color: #fff;
      }
    }
  }
}

.link {
  font-weight: bold;
  color: #0a5753;
  cursor: pointer;
}

.code {
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: underline;
}

.disclaimer {
  font-size: 12px;
  margin-top: 25px;
}

.popup__close {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 40px;
  height: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.popup__close:before,
.popup__close:after {
  content: "";
  position: absolute;
  top: 18px;
  left: 0;
  right: 0;
  height: 4px;
  background: #fff;
  border-radius: 4px;
}
.popup__close:before {
  transform: rotate(45deg);
}
.popup__close:after {
  transform: rotate(-45deg);
}
.popup__close span {
  display: block;
}
</style>
