<template>
  <section class="section__content">
    <h2 class="section__title">1. Selecciona tu estilo</h2>
    <div class="shapes" v-if="!loading">
      <div
        :key="shape"
        v-for="shape in shapes"
        :class="[
          'shape',
          'shape--' + shape,
          { 'shape--active': isActive(shape) },
        ]"
        @click="setShape(shape)"
      ></div>
    </div>
    <Loader v-else />
  </section>
</template>
<script>
import Loader from "../Loader.vue";
export default {
  props: {
    shapes: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      activeShape: [],
      loading: true,
    };
  },
  watch: {
    shapes() {
      const [firstShape] = this.shapes;
      this.activeShape = firstShape;
      this.$emit("shape", firstShape);
      this.loading = false;
    },
  },
  methods: {
    setShape(shape) {
      this.activeShape = shape;
      this.$emit("shape", shape);
    },
    isActive(shape) {
      if (!this.activeShape) {
        return;
      }

      return this.activeShape.includes(shape);
    },
  },
};
</script>
<style lang="scss" scope>
.shapes {
  display: flex;
  gap: 15px;
  align-items: center;
  justify-content: center;
}

.shape {
  width: 60px;
  height: 60px;
  background: #d0cec8;
  cursor: pointer;

  &--circle {
    border-radius: 50%;
  }

  &--bone {
    background: #d0cec8;
    height: 40px;
    width: 50%;
    max-width: 100px;
    position: relative;
    scale: 0.65;
  }

  &--active {
    background: #0a5753;
    animation: pulse 1.5s infinite;
  }
}

.shape--bone::before,
.shape--bone::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  top: -20px;
  width: 40px;
  height: 40px;
  background: #d0cec8;
  box-shadow: 0 40px 0 #d0cec8;
}

.shape--bone::before {
  left: -20px;
}
.shape--bone::after {
  right: -20px;
}

.shape--bone.shape--active::before,
.shape--bone.shape--active::after {
  background: #0a5753;
  box-shadow: 0 40px 0 #0a5753;
}
</style>
