<template>
  <div>
    <div class="title">
      <h1>¡Crea la <u>Placa Perfecta</u> para tu peludo!</h1>
      <p>
        ¡Selecciona la forma y color que más te guste, añade tus datos y dale
        ese toque especial que solo tú puedes dar! 😍
      </p>
    </div>
    <div class="preview">
      <div v-show="!loading">
        <div class="button__group">
          <button
            class="button button--small"
            :class="{ 'button--active': showFront }"
            @click="showFront = true"
          >
            Frontal
          </button>
          <button
            class="button button--small"
            :class="{ 'button--active': !showFront }"
            @click="showFront = false"
          >
            Reverso
          </button>
        </div>
        <div
          class="preview__container"
          :class="{
            'preview__container--small': showSmallPreview,
          }"
          @click="$emit('scrollUp', showSmallPreview)"
        >
          <div
            v-show="shape === 'bone'"
            class="canvas canvas--bone"
            :class="canvasClasses"
            ref="svgBoneContainer"
          ></div>
          <div
            v-show="shape === 'circle'"
            class="canvas canvas--avenir canvas--circle"
            :class="canvasClasses"
            ref="svgCircleContainer"
          ></div>
          <p class="canvas__disclaimer">
            *Ten en cuenta que esta imagen es una referencia y el resultado
            final puede <u>superar tus expectativas</u> 😉
          </p>
        </div>
        <button @click="showPopup" class="button button--pulse">
          ¡Quiero esta placa! 😍
        </button>
      </div>
      <Loader v-show="loading" />
    </div>
  </div>
</template>
<script>
import * as d3 from "d3";
import Loader from "../Loader.vue";
import html2canvas from "html2canvas";
import Bone from "../../assets/bone.svg";
import Circle from "../../assets/circle.svg";

export default {
  props: {
    color: {
      type: String,
    },
    shape: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    nickname: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
    altPhone: {
      type: String,
      default: "",
    },
    font: {
      type: String,
      default: "bebas",
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      svgBoneContainer: null,
      svgCircleContainer: null,
      showFront: true,
      showSmallPreview: false,
      loading: true,
      dataUrl: "",
    };
  },
  async mounted() {
    await this.loadShapes();
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll);
  },
  watch: {
    color() {
      this.updateColor(this.currentContainer);
      this.loading = false;
    },
    shape() {
      this.updateColor(this.currentContainer);
      this.loading = false;
    },
    name() {
      this.updateText(this.svgBoneContainer, this.name, ".name");
      this.updateText(this.svgCircleContainer, this.name, ".name");
    },
    nickname() {
      this.updateText(this.svgBoneContainer, this.nickname, ".nickname");
      this.updateText(this.svgCircleContainer, this.nickname, ".nickname");
    },
    phone() {
      this.updateText(this.svgBoneContainer, this.phone, ".phone");
      this.updateText(this.svgCircleContainer, this.phone, ".phone");
    },
    altPhone() {
      this.updateText(this.svgBoneContainer, this.altPhone, ".altPhone");
      this.updateText(this.svgCircleContainer, this.altPhone, ".altPhone");
    },
  },
  computed: {
    currentContainer() {
      return this.shape === "bone"
        ? this.svgBoneContainer
        : this.svgCircleContainer;
    },
    fileName() {
      return `${this.name}`.toLocaleLowerCase();
    },
    nameFontSize() {
      const length = this.name.length;
      return length >= 15
        ? "canvas--small-name"
        : length >= 8
        ? "canvas--medium-name"
        : "canvas--large-name";
    },
    canvasClasses() {
      const fontClass = `canvas--${this.font}`;

      return {
        "canvas--front": this.showFront,
        "canvas--back": !this.showFront,
        [fontClass]: this.showFront,
        [this.nameFontSize]: this.showFront,
      };
    },
  },
  methods: {
    async loadShapes() {
      this.svgBoneContainer = d3.select(this.$refs.svgBoneContainer);
      this.svgCircleContainer = d3.select(this.$refs.svgCircleContainer);

      const svgBone = await d3.xml(Bone);
      this.svgBoneContainer.node().appendChild(svgBone.documentElement);
      this.addName(this.svgBoneContainer, this.name, "name");
      this.addName(this.svgBoneContainer, this.nickname, "nickname");
      this.addName(this.svgBoneContainer, this.phone, "phone");
      this.addName(this.svgBoneContainer, this.altPhone, "altPhone");
      this.updateColor(this.svgBoneContainer);

      const svgCircle = await d3.xml(Circle);
      this.svgCircleContainer.node().appendChild(svgCircle.documentElement);
      this.addName(this.svgCircleContainer, this.name, "name");
      this.addName(this.svgCircleContainer, this.nickname, "nickname");
      this.addName(this.svgCircleContainer, this.phone, "phone");
      this.addName(this.svgCircleContainer, this.altPhone, "altPhone");
      this.updateColor(this.svgCircleContainer);
    },
    addName(container, text, className) {
      container.append("text").text(text).attr("class", className);
    },
    updateColor(container) {
      const rect = container.select(".shape");
      if (!rect.empty()) {
        rect.attr("fill", this.color);
      }
    },
    updateText(container, text, selector) {
      const textElement = container.select(selector);
      textElement.text(text);
    },
    handleScroll() {
      const scrollY = window.scrollY || window.pageYOffset;
      if (scrollY > 650) {
        this.showSmallPreview = true;
      } else {
        this.showSmallPreview = false;
      }
    },
    async showPopup() {
      this.showFront = true;
      this.$emit("showPopup");
    },
    async createDataUrlImage() {
      const imageType = "jpg";
      const svgElement = this.currentContainer.node();

      // Use html2canvas to capture the SVG container
      const canvas = await html2canvas(svgElement);
      return canvas.toDataURL(`image/${imageType}`);
    },
  },
};
</script>
<style lang="scss">
.title {
  margin: 40px 20px 0;
  h1 {
    margin: 20px 0 10px 0;

    u {
      color: #0a5753;
    }

    @media all and (max-width: 420px) {
      width: 80%;
      margin: 20px auto;
    }
  }
  p {
    font-size: 0.9em;
    width: 80%;
    margin: 0 auto;
  }
}

.canvas__disclaimer {
  font-size: 0.75em;
  width: 300px;
  margin: -30px auto 40px;
}

.preview {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  flex-direction: column;

  @media all and (max-width: 1024px) {
    padding: 50px 0;
    border-right: none;
    height: auto;
  }

  @media all and (max-width: 420px) {
    padding: 20px 0;
  }
}

.preview__container {
  &--small {
    height: 477px;
    width: 1px;

    .canvas {
      cursor: pointer;
      position: fixed !important;
      z-index: 1;
      scale: 0.4;
      background: #fff;
      right: -100px;
      top: -100px;
      border-radius: 10px;
      box-shadow: -1px 0px 11px 1px rgb(0 0 0 / 31%);

      @media all and (min-width: 768px) {
        scale: 0.6;
        right: -85px;
        top: -68px;
        width: 500px;

        &--circle {
          top: -55px !important;
          margin-bottom: 0;
        }
      }

      &--circle {
        top: -82px;
        margin-bottom: 0;
      }
    }
  }
}

.canvas {
  height: 400px;
  width: 400px;
  position: relative;

  @media all and (max-width: 1024px) {
    width: 100%;
  }

  @media all and (max-width: 410px) {
    width: 400px;
    margin-left: -18px;
    svg {
      width: 100%;
    }
  }

  text {
    display: none;
  }

  &--large-name {
    text.name {
      font-size: 26px;
    }

    &.canvas--bebas {
      text.name {
        font-size: 34px;
      }
    }

    &.canvas--salsa {
      text.name {
        font-size: 25px;
      }
    }
  }

  &--medium-name {
    &.canvas--avenir {
      text.name {
        font-size: 20px;
      }
    }

    &.canvas--bebas {
      text.name {
        font-size: 32px;
      }
    }

    &.canvas--salsa {
      text.name {
        font-size: 23px;
      }
    }
  }

  &--small-name {
    &.canvas--avenir {
      text.name {
        font-size: 15px;
      }
    }

    &.canvas--bebas {
      text.name {
        font-size: 20px;
      }
    }

    &.canvas--salsa {
      text.name {
        font-size: 16px;
      }
    }
  }

  &--avenir {
    &.canvas--front {
      font-family: Avenir;
    }
  }
  &--bebas {
    &.canvas--front {
      font-family: "Bebas Neue", sans-serif;

      text.nickname {
        font-size: 23px !important;
        line-height: 25px !important;
      }
    }
  }

  &--salsa {
    &.canvas--front {
      font-family: "Salsa", cursive;

      text.nickname {
        font-size: 20px !important;
        line-height: 25px !important;
      }
    }
  }

  &--front {
    text.name,
    text.nickname {
      display: block;
    }
  }

  &--back {
    text.phone,
    text.altPhone {
      display: block;
    }
  }

  &--circle {
    height: 340px;
    margin-bottom: 60px;

    text.name {
      top: 67%;
      font-size: 24px;
    }

    text.nickname {
      top: 78%;
      width: 105px;
    }

    text.phone {
      top: 70%;
      width: 120px;
      font-size: 16px;
    }
    text.altPhone {
      top: 75%;
      width: 120px;
      font-size: 14px;
    }
  }

  &--bone {
    text.name {
      top: 58%;
    }
    text.nickname {
      top: 66%;
      width: 125px;
    }
    text.phone {
      top: 59%;
      width: 125px;
      font-size: 16px;
    }
    text.altPhone {
      top: 64%;
      width: 125px;
      font-size: 15px;
    }
  }

  svg {
    height: 100%;
    position: relative;
  }

  text {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    color: #252b28;
    font-size: 26px;
    overflow: hidden;

    &.nickname {
      font-size: 18px;
      line-height: 18px;
    }
  }
}
</style>
