import { setDoc, doc } from "firebase/firestore";

export const createOrder = async (db, code, order) => {
  try {
    await setDoc(doc(db, "orders", code), {
      ...order,
    });
  } catch (e) {
    console.log(e);
  }
};
