<template>
  <div>
    <header class="header" id="header">
      <img
        src="./assets/logo-pacos.svg"
        class="header__logo"
        alt="The Pacos Store"
      />
    </header>
    <div class="app" id="app">
      <aside class="column column--white">
        <Canvas
          ref="canvas"
          id="canvas"
          :color="color"
          :shape="shape"
          :name="name"
          :nickname="nickname"
          :phone="phone"
          :altPhone="altPhone"
          :font="font"
          @showPopup="showNextStep"
          @scrollUp="scrollUp"
        />
      </aside>
      <main class="column">
        <Shape
          class="section section--shapes"
          :shapes="shapes"
          @shape="setShape"
        />
        <Color
          class="section section--color"
          :colors="colors"
          :shape="shape"
          @color="setColor"
        />
        <Info
          class="section section--info"
          @name="setName"
          @nickname="setNickName"
          @phone="setPhone"
          @altPhone="setAltPhone"
        />
        <Font @setFont="setFont" @showPopup="showNextStep" />
      </main>
      <footer class="footer">
        <h3>The Pacos Store © 2023</h3>
        <ul class="social">
          <li class="social__item">
            <a href="https://www.instagram.com/thepacostore.co/" target="_blank"
              ><img src="./assets/instagram.svg"
            /></a>
          </li>
          <li class="social__item">
            <a href="https://wa.me/+573147555587" target="_blank"
              ><img src="./assets/whatsapp.svg"
            /></a>
          </li>
        </ul>
      </footer>
      <Popup v-if="showPopup" :code="code" @closePopup="showPopup = false" />
    </div>
  </div>
</template>

<script>
import { db } from "./server/index";
import { getShapes } from "./server/shapesProvider";
import { createOrder } from "./server/orderProvider";

import Canvas from "./components/BadgeMaker/Canvas.vue";
import Shape from "./components/BadgeMaker/Shape.vue";
import Color from "./components/BadgeMaker/Color.vue";
import Info from "./components/BadgeMaker/Info.vue";
import Font from "./components/BadgeMaker/Font.vue";
import Popup from "./components/Popup.vue";

import { generateId } from "./utils/uuid";
import { orderMapper, COLORS } from "./utils/mapOrder";

export default {
  name: "App",
  components: {
    Canvas,
    Shape,
    Color,
    Info,
    Popup,
    Font,
  },
  data() {
    return {
      DATA: {},
      shape: "",
      color: "",
      name: "",
      nickname: "",
      phone: "",
      altPhone: "",
      showPopup: false,
      font: "avenir",
    };
  },
  async created() {
    const availability = await getShapes(db);

    this.DATA = {
      shapes: ["bone", "circle"],
      ...availability,
    };
  },
  watch: {
    showPopup(value) {
      const [html] = document.getElementsByTagName("html");
      const [body] = document.getElementsByTagName("body");

      if (value) {
        body.classList.add("no-overflow");
        html.classList.add("no-overflow");
      } else {
        body.classList.remove("no-overflow");
        html.classList.remove("no-overflow");
      }
    },
  },
  computed: {
    shapes() {
      return this.DATA.shapes;
    },
    colors() {
      return this.DATA[this.shape];
    },
  },
  methods: {
    setShape(shape) {
      this.shape = shape;
      window.gtag("event", `shape_${shape}`);
    },
    setColor(color) {
      this.color = color;
      window.gtag("event", `color_${COLORS[color]}`);
    },
    setName(name) {
      this.name = name;
    },
    setNickName(nickname) {
      this.nickname = nickname;
    },
    setPhone(phone) {
      this.phone = phone;
    },
    setAltPhone(phone) {
      this.altPhone = phone;
    },
    setFont(font) {
      this.font = font;
      window.gtag("event", `font_${font}`);
    },
    async showNextStep() {
      this.code = generateId();
      this.showPopup = true;

      await this.createOrder();
      window.gtag("event", "popup_opened");
    },
    async createOrder() {
      const orderInfo = {
        shape: this.shape,
        color: this.color,
        name: this.name,
        nickname: this.nickname,
        phone: this.phone,
        altPhone: this.altPhone,
        date: new Date(),
        font: this.font,
      };

      await createOrder(db, this.code, orderMapper(orderInfo));
    },
    scrollToTop() {
      const element = document.getElementById("header");
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    scrollUp(isPreview) {
      if (isPreview) {
        this.scrollToTop();
      }
    },
  },
};
</script>

<style lang="scss" scope>
$green: #0a5753;

* {
  box-sizing: border-box;
}

.no-overflow {
  overflow: hidden;
}

body {
  margin: 0;
  overflow: hidden;

  @media all and (max-width: 1024px) {
    overflow-y: scroll;
  }

  @media all and (max-height: 900px) {
    overflow-y: scroll;
  }
}

body::before {
  content: "";
  background-image: url("./assets/pattern.jpg");
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: grayscale(100%);
  background-size: 40%;
  opacity: 0.08;

  @media all and (max-width: 560px) {
    background-size: 100%;
  }
}

.app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100vh;

  @media all and (max-width: 1024px) {
    grid-template-columns: 1fr;
    height: auto;
  }
}

.column {
  display: flex;
  flex-direction: column; /* Establece la dirección de los hijos a columnas */
  height: calc(100% - 114px);

  &--white {
    background: #fff;
    border-right: solid 2px #e8e8e8;
  }

  @media all and (max-width: 1024px) {
    height: auto;
  }
}

.header {
  padding: 15px 0;
  height: auto;
  box-sizing: border-box;
  background-color: $green;
  border-bottom: solid 3px #f3b74f;

  display: flex;
  align-items: center;
  justify-content: center;

  &__logo {
    width: 85px;
  }

  &__title {
    color: #fff;
    margin: 0;
  }
  &__description {
    margin: 0;
    color: #fff;
  }
}

.section {
  background-color: transparent;
  padding: 32px 30px;
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &__title {
    margin: 0 0 15px 0;
    color: $green;
    font-size: 1.4em;
  }

  &:nth-child(even) {
    background-color: $green;

    .section__title {
      color: #fff;
    }
  }
}

.button__group {
  .button {
    &:nth-child(1) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:nth-child(2) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
.button {
  text-decoration: none;
  text-align: center;
  background-color: $green;
  color: #fff;
  border: none;
  padding: 20px;
  border-radius: 4px;
  font-weight: bold;
  border-bottom: solid 3px #f3b74f;
  cursor: pointer;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: Avenir, Helvetica, Arial, sans-serif;

  &--full-width {
    width: 100%;
  }

  &--center {
    margin: 0 auto;
    display: block;
  }

  &--pulse {
    animation: pulse 2.5s infinite;
  }

  &--info {
    display: none;

    @media all and (max-width: 1024px) {
      display: block;
      margin: 30px auto 0;
    }
  }

  &--small {
    padding: 10px;
    background-color: #d0cec8;
    border-color: #aba9a5;
  }

  &--active {
    background-color: $green;
    border-color: #033d3a;
  }

  &--yellow {
    background-color: #f4b74f;
    border-color: #e7a026;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
}

.footer {
  background: #03302d;
  display: none;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  @media all and (max-width: 1024px) {
    display: flex;
  }

  h3 {
    color: #fff;
    font-weight: 500;
    font-size: 1em;
  }

  .social {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 20px;

    &__item {
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
      }
    }
  }
}
</style>
